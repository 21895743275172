import React from 'react';

const ErrorPage = () => {
  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f4f4f4',
      color: '#333',
      margin: 0,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <div style={{
        textAlign: 'center'
      }}>
        <h1 style={{
          fontSize: '3rem',
          marginBottom: '20px'
        }}>Error - Access Denied</h1>
        <p style={{
          fontSize: '1.2rem',
          marginBottom: '30px'
        }}>Sorry, you do not have permission to access this page.</p>
      </div>
    </div>
  );
};

export default ErrorPage;