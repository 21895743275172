import React, { useEffect, useRef, useState } from 'react';
import './CardOne.css';
import image from './scratch-image-background.png';
import glitter from './glitter.png';

function ScratchCard() {
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Proposal Card";
    const canvas = canvasRef.current;
    const img = imgRef.current;
    const ctx = canvas.getContext('2d');
    const customShapeImg = new Image();
    customShapeImg.src = glitter;

    // Define your circle data
    const circlesData = [
        { x: 180, y: 413, radius: 122 },
    ];

    let circles = []; // This will store scaled circle data

    function updateCirclePositions() {
        const scaleX = canvas.width / 360;
        const scaleY = canvas.height / 640;
        circles = circlesData.map(circle => ({
            x: circle.x * scaleX,
            y: circle.y * scaleY,
            radius: circle.radius * Math.min(scaleX, scaleY)
        }));
    }

    function drawCircles() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#cccccc';
        circles.forEach(circle => {
            const imageWidth = circle.radius * 2 * 1.42;
            const imageHeight = circle.radius * 2;
            const imageX = circle.x - imageWidth / 2;
            const imageY = circle.y - imageHeight / 2;
            ctx.drawImage(customShapeImg, imageX, imageY, imageWidth, imageHeight);
        });
        ctx.globalCompositeOperation = 'destination-out';
    }
    
    function resizeCanvas() {
        canvas.width = img.width;
        canvas.height = img.height;
        updateCirclePositions();
        drawCircles();
    }

    customShapeImg.onload = () => {
        resizeCanvas(); // Resize canvas once the custom shape image is loaded
    };

    // Resize canvas on page load
    resizeCanvas();

    // Listen for resize events to adjust canvas and circle sizes
    window.addEventListener('resize', resizeCanvas);

    if (img.complete) {
      resizeCanvas(); // If the image is already loaded (e.g., cached), resize immediately
    } else {
      img.onload = resizeCanvas; // Wait for the image to load before resizing
    }

    function getCoordinates(e) {
        const rect = canvas.getBoundingClientRect();
        let x, y;
        
        if (e.touches) {
            x = e.touches[0].clientX - rect.left;
            y = e.touches[0].clientY - rect.top;
        } else {
            x = e.clientX - rect.left;
            y = e.clientY - rect.top;
        }
        
        return { x, y };
    }

    let lastX, lastY, isDrawing = false;

    function scratch(e) {
        const { x, y } = getCoordinates(e);
    
        if (isDrawing) {
            if (lastX == null || lastY == null) {
                lastX = x; // Set starting position if not already set
                lastY = y;
            }
        }
    
        circles.forEach(circle => {
            ctx.lineWidth = 15;
            ctx.beginPath();
            ctx.moveTo(lastX, lastY);
            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.closePath();
        });
    
        [lastX, lastY] = [x, y];
    }

    // Mouse event listeners
    canvas.addEventListener('mousedown', function(e) {
        isDrawing = true; // Start drawing
        lastX = null; // Reset lastX and lastY
        lastY = null;
        scratch(e); // Handle initial touch point
    });
    
    canvas.addEventListener('mousemove', function(e) {
        if (isDrawing) {
            scratch(e);
        }
    });
    
    canvas.addEventListener('mouseup', function() {
        isDrawing = false; // Stop drawing
    });
    
    canvas.addEventListener('mouseout', function() {
        isDrawing = false; // Stop drawing when the cursor leaves the canvas
    });

    canvas.addEventListener('touchstart', function(e) {
        e.preventDefault(); // Prevent scrolling and other default actions
        isDrawing = true;
        lastX = null; // Reset lastX and lastY
        lastY = null;
        scratch(e.touches[0]); // Use the first touch point to start
    });
    
    canvas.addEventListener('touchmove', function(e) {
        e.preventDefault();
        if (isDrawing) {
            scratch(e.touches[0]); // Continue with the first touch point
        }
    });
    
    canvas.addEventListener('touchend', function(e) {
        isDrawing = false; // Stop drawing when touch ends
    });
    
    canvas.addEventListener('touchcancel', function(e) {
        isDrawing = false; // Also stop drawing when touch is interrupted
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000); // Hide the spinner after 1 second
  }, []);

  return (
    <div className="scratch-card">
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <canvas ref={canvasRef} id="scratchCanvas"></canvas>
      <img ref={imgRef} src={image} alt="Scratch Card" />
    </div>
  );
}

export default ScratchCard;