import React from 'react';
import './App.css';
import ScratchCard from './Card/CardOne/ScratchCard'
import MothersDayOne from './Card/MothersDay/MothersDayOne/ScratchCard'
import MothersDayTwo from './Card/MothersDay/MothersDayTwo/ScratchCard'
import MothersDayThree from './Card/MothersDay/MothersDayThree/ScratchCard'
import MothersDayFour from './Card/MothersDay/MothersDayFour/ScratchCard'
import MothersDayFive from './Card/MothersDay/MothersDayFive/ScratchCard'
import NaughtDateNight from './Card/NaughtyDateNight/ScratchCard'
import PositionMission from './Card/PositionMission/ScratchCard'
import BirthdayCard from './Card/BirthdayCard/ScratchCard'
import Demo from './Card/Demo/ScratchCard'
import FathersDayOne from './Card/FathersDay/FathersDayOne/ScratchCard'
import FathersDayTwo from './Card/FathersDay/FathersDayTwo/ScratchCard'
import FathersDayThree from './Card/FathersDay/FathersDayThree/ScratchCard'
import FathersDayFour from './Card/FathersDay/FathersDayFour/ScratchCard'
import FathersDayFive from './Card/FathersDay/FathersDayFive/ScratchCard'
import KamaSutraChallenge from './Card/KamaSutraChallenge/ScratchCard'
import KamaSutraCalendar from './Card/KamaSutraCalendar/ScratchCard'
import NaughtScratchCard1 from './Card/Naughty/NaughtyScratchCard1/ScratchCard'
import NaughtScratchCard2 from './Card/Naughty/NaughtyScratchCard2/ScratchCard'
import NaughtScratchCard3 from './Card/Naughty/NaughtyScratchCard3/ScratchCard'
import NaughtScratchCard4 from './Card/Naughty/NaughtyScratchCard4/ScratchCard'
import NaughtScratchCard5 from './Card/Naughty/NaughtyScratchCard5/ScratchCard'
import NaughtScratchCard6 from './Card/Naughty/NaughtyScratchCard6/ScratchCard'
import NaughtScratchCard7 from './Card/Naughty/NaughtyScratchCard7/ScratchCard'
import NaughtScratchCard8 from './Card/Naughty/NaughtyScratchCard8/ScratchCard'
import NaughtScratchCard9 from './Card/Naughty/NaughtyScratchCard9/ScratchCard'
import NaughtScratchCard10 from './Card/Naughty/NaughtyScratchCard10/ScratchCard'
import NaughtScratchCard11 from './Card/Naughty/NaughtyScratchCard11/ScratchCard'
import NaughtScratchCard12 from './Card/Naughty/NaughtyScratchCard12/ScratchCard'
import NaughtScratchCard13 from './Card/Naughty/NaughtyScratchCard13/ScratchCard'
import NaughtScratchCard14 from './Card/Naughty/NaughtyScratchCard14/ScratchCard'
import NaughtScratchCard15 from './Card/Naughty/NaughtyScratchCard15/ScratchCard'
import Sexmas from './Card/Sexmas/ScratchCard'
import GenderRevealCardOne from './Card/GenderRevealCard/GenderRevealCardOne/ScratchCard'
import GenderRevealCardTwo from './Card/GenderRevealCard/GenderRevealCardTwo/ScratchCard'
import GenderRevealCardThree from './Card/GenderRevealCard/GenderRevealCardThree/ScratchCard'
import GenderRevealCardFour from './Card/GenderRevealCard/GenderRevealCardFour/ScratchCard'
import GodparentProposalCardOne from './Card/GodparentProposalCard/GodparentProposalCardOne/ScratchCard'
import GodparentProposalCardTwo from './Card/GodparentProposalCard/GodparentProposalCardTwo/ScratchCard'
import GodparentProposalCardThree from './Card/GodparentProposalCard/GodparentProposalCardThree/ScratchCard'
import GodparentProposalCardFour from './Card/GodparentProposalCard/GodparentProposalCardFour/ScratchCard'
import GodparentProposalCardFive from './Card/GodparentProposalCard/GodparentProposalCardFive/ScratchCard'
import GodparentProposalCardSix from './Card/GodparentProposalCard/GodparentProposalCardSix/ScratchCard'
import GodparentProposalCardSeven from './Card/GodparentProposalCard/GodparentProposalCardSeven/ScratchCard'
import GodparentProposalCardEight from './Card/GodparentProposalCard/GodparentProposalCardEight/ScratchCard'
import GodparentProposalCardNine from './Card/GodparentProposalCard/GodparentProposalCardNine/ScratchCard'
import GodparentProposalCardTen from './Card/GodparentProposalCard/GodparentProposalCardTen/ScratchCard'
import GodparentProposalCardEleven from './Card/GodparentProposalCard/GodparentProposalCardEleven/ScratchCard'
import BirthdayCardTwo from './Card/BirthdayCard/BirthdayCardTwo/ScratchCard'
import BirthdayCardThree from './Card/BirthdayCard/BirthdayCardThree/ScratchCard'
import AnniversaryCard from './Card/AnniversaryCard/ScratchCard'
import AnniversaryCardTwo from './Card/AnniversaryCard/AnniversaryCardTwo/ScratchCard'
import ChristmasCard from './Card/ChristmasCard/ScratchCard'
import ChristmasCardTwo from './Card/ChristmasCard/ChristmasCardTwo/ScratchCard'
import ChristmasCardThree from './Card/ChristmasCard/ChristmasCardThree/ScratchCard'
import NaughtDateNightTwo from './Card/NaughtyDateNight/NaughtDateNightTwo/ScratchCard'
import PositionMissionTwo from './Card/PositionMission/PositionMissionTwo/ScratchCard'
import ErrorPage from './Error';
import ProposalCardOne from './Card/ProposalCard/ProposalCardOne/ScratchCard'
import ProposalCardTwo from './Card/ProposalCard/ProposalCardTwo/ScratchCard'
import ProposalCardThree from './Card/ProposalCard/ProposalCardThree/ScratchCard'
import ProposalCardFour from './Card/ProposalCard/ProposalCardFour/ScratchCard'
import ProposalCardFive from './Card/ProposalCard/ProposalCardFive/ScratchCard'
import ProposalCardSix from './Card/ProposalCard/ProposalCardSix/ScratchCard'
import ProposalCardSeven from './Card/ProposalCard/ProposalCardSeven/ScratchCard'
import ProposalCardEight from './Card/ProposalCard/ProposalCardEight/ScratchCard'
import ProposalCardNine from './Card/ProposalCard/ProposalCardNine/ScratchCard'
import ProposalCardTen from './Card/ProposalCard/ProposalCardTen/ScratchCard'
import ProposalCardEleven from './Card/ProposalCard/ProposalCardEleven/ScratchCard'
import ProposalCardTwelve from './Card/ProposalCard/ProposalCardTwelve/ScratchCard'
import GuessWhatCardOne from './Card/GuessWhatCard/GuessWhatCardOne/ScratchCard'
import GuessWhatCardTwo from './Card/GuessWhatCard/GuessWhatCardTwo/ScratchCard'
import GuessWhatCardThree from './Card/GuessWhatCard/GuessWhatCardThree/ScratchCard'
import GuessWhatCardFour from './Card/GuessWhatCard/GuessWhatCardFour/ScratchCard'
import GuessWhatCardFive from './Card/GuessWhatCard/GuessWhatCardFive/ScratchCard'
import GuessWhatCardSix from './Card/GuessWhatCard/GuessWhatCardSix/ScratchCard'
import GuessWhatCardSeven from './Card/GuessWhatCard/GuessWhatCardSeven/ScratchCard'
import SexmasTwo from './Card/SexmasTwo/ScratchCard'
import KamaSutraCalendarPartOne from './Card/KamasutraCalendarTwo/ScratchCardPartOne'
import KamaSutraCalendarPartTwo from './Card/KamasutraCalendarTwo/ScratchCardPartTwo'
import KamasutraAdventskalendarPartOne from './Card/KamasutraAdventsKalendar/ScratchCardPartOne'
import KamasutraAdventskalendarPartTwo from './Card/KamasutraAdventsKalendar/ScratchCardPartTwo'
import ValentinesCardOne from './Card/ValentinesCard/ValentinesCardOne/ScratchCard'
import ValentinesCardTwo from './Card/ValentinesCard/ValentinesCardTwo/ScratchCard'
import AdventCalendar1 from './QR/1.png'
import AdventCalendar2 from './QR/2.png'
import AdventCalendar3 from './QR/3.png'
import AdventCalendar4 from './QR/4.png'
import AdventCalendar5 from './QR/5.png'
import AdventCalendar6 from './QR/6.png'
import AdventCalendar7 from './QR/7.png'
import AdventCalendar8 from './QR/8.png'
import AdventCalendar9 from './QR/9.png'
import AdventCalendar10 from './QR/10.png'
import AdventCalendar11 from './QR/11.png'
import AdventCalendar12 from './QR/12.png'
import AdventCalendar13 from './QR/13.png'
import AdventCalendar14 from './QR/14.png'
import AdventCalendar15 from './QR/15.png'
import AdventCalendar16 from './QR/16.png'
import AdventCalendar17 from './QR/17.png'
import AdventCalendar18 from './QR/18.png'
import AdventCalendar19 from './QR/19.png'
import AdventCalendar20 from './QR/20.png'
import AdventCalendar21 from './QR/21.png'
import AdventCalendar22 from './QR/22.png'
import AdventCalendar23 from './QR/23.png'
import AdventCalendar24 from './QR/24.png'
import AdventCalendar25 from './QR/25.png'

import { useLocation, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';

function CheckToken({ children }) {
  const [isValidToken, setIsValidToken] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    let query = new URLSearchParams(location.search);
    let token = query.get('token');

    if (!token || token !== 'vatghrahtbhrba') {
      setIsValidToken(false);
    }
  }, [location]);

  if (!isValidToken) {
    return <ErrorPage />;
  }

  return children;
}

function App() {

  const isMobile = window.innerWidth <= 900;
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");

    const handleOrientationChange = (e) => {
      setIsPortrait(e.matches);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  return (
    <Router>
      {(isMobile && !isPortrait) ? 
      <p>Please rotate the device to view the scratch card!</p>
        :
      <CheckToken>
        <Routes>
          {/* Naughty Scratch Card Route */}
          <Route path="/" element={<ScratchCard />} />
          <Route path="/naughty-scratch-card-one" element={<NaughtScratchCard1 />} />
          <Route path="/naughty-scratch-card-two" element={<NaughtScratchCard2 />} />
          <Route path="/naughty-scratch-card-three" element={<NaughtScratchCard3 />} />
          <Route path="/naughty-scratch-card-four" element={<NaughtScratchCard4 />} />
          <Route path="/naughty-scratch-card-five" element={<NaughtScratchCard5 />} />
          <Route path="/naughty-scratch-card-six" element={<NaughtScratchCard6 />} />
          <Route path="/naughty-scratch-card-seven" element={<NaughtScratchCard7 />} />
          <Route path="/naughty-scratch-card-eight" element={<NaughtScratchCard8 />} />
          <Route path="/naughty-scratch-card-nine" element={<NaughtScratchCard9 />} />
          <Route path="/naughty-scratch-card-ten" element={<NaughtScratchCard10 />} />
          <Route path="/naughty-scratch-card-eleven" element={<NaughtScratchCard11 />} />
          <Route path="/naughty-scratch-card-twelve" element={<NaughtScratchCard12 />} />
          <Route path="/naughty-scratch-card-thirteen" element={<NaughtScratchCard13 />} />
          <Route path="/naughty-scratch-card-fourteen" element={<NaughtScratchCard14 />} />
          <Route path="/naughty-scratch-card-fifteen" element={<NaughtScratchCard15 />} />

          {/* Error Route */}
          <Route path="/*" element={<ErrorPage />} />

          {/* Mother's Day Routes */}
          <Route path="/mothers-day-one" element={<MothersDayOne />} />
          <Route path="/mothers-day-two" element={<MothersDayTwo />} />
          <Route path="/mothers-day-three" element={<MothersDayThree />} />
          <Route path="/mothers-day-four" element={<MothersDayFour />} />
          <Route path="/mothers-day-five" element={<MothersDayFive />} />

          {/* Naughty Date Night Route */}
          <Route path="/naughty-date-night" element={<NaughtDateNight />} />
          <Route path="/naughty-date-night-two" element={<NaughtDateNightTwo />} />

          {/* Position Mission Route */}
          <Route path="/position-mission" element={<PositionMission />} />
          <Route path="/position-mission-two" element={<PositionMissionTwo />} />

          {/* Birthday Card Route */}
          <Route path="/birthday-card" element={<BirthdayCard />} />
          <Route path="/birthday-card-two" element={<BirthdayCardTwo />} />
          <Route path="/birthday-card-three" element={<BirthdayCardThree />} />

          {/* Demo Card Route */}
          <Route path="/demo" element={<Demo />} />

          {/* Fathers's Day Routes */}
          <Route path="/fathers-day-one" element={<FathersDayOne />} />
          <Route path="/fathers-day-two" element={<FathersDayTwo />} />
          <Route path="/fathers-day-three" element={<FathersDayThree />} />
          <Route path="/fathers-day-four" element={<FathersDayFour />} />
          <Route path="/fathers-day-five" element={<FathersDayFive />} />

          {/* Sexmas Card Route */}
          <Route path="/sexmas" element={<Sexmas />} />
          <Route path="/sexmas-two" element={<SexmasTwo />} />

          {/* Kama Sutra Card Route */}
          <Route path="/kamasutra-calendar" element={<KamaSutraCalendar />} />
          <Route path="/kamasutra-challenge" element={<KamaSutraChallenge />} />

          {/* Gender Reveal Card Routes */}
          <Route path="/gender-reveal-card-one" element={<GenderRevealCardOne />} />
          <Route path="/gender-reveal-card-two" element={<GenderRevealCardTwo />} />
          <Route path="/gender-reveal-card-three" element={<GenderRevealCardThree />} />
          <Route path="/gender-reveal-card-four" element={<GenderRevealCardFour />} />

          {/* Godparent Proposal Card Routes */}
          <Route path="/gp-proposal-card-one" element={<GodparentProposalCardOne />} />
          <Route path="/gp-proposal-card-two" element={<GodparentProposalCardTwo />} />
          <Route path="/gp-proposal-card-three" element={<GodparentProposalCardThree />} />
          <Route path="/gp-proposal-card-four" element={<GodparentProposalCardFour />} />
          <Route path="/gp-proposal-card-five" element={<GodparentProposalCardFive />} />
          <Route path="/gp-proposal-card-six" element={<GodparentProposalCardSix />} />
          <Route path="/gp-proposal-card-seven" element={<GodparentProposalCardSeven />} />
          <Route path="/gp-proposal-card-eight" element={<GodparentProposalCardEight />} />
          <Route path="/gp-proposal-card-nine" element={<GodparentProposalCardNine />} />
          <Route path="/gp-proposal-card-ten" element={<GodparentProposalCardTen />} />
          <Route path="/gp-proposal-card-eleven" element={<GodparentProposalCardEleven />} />

          {/* Anniversary Card Route */}
          <Route path="/anniversary-card" element={<AnniversaryCard />} />
          <Route path="/anniversary-card-two" element={<AnniversaryCardTwo />} />

          {/* Christmas Card Route */}
          <Route path="/christmas-card" element={<ChristmasCard />} />
          <Route path="/christmas-card-two" element={<ChristmasCardTwo />} />
          <Route path="/christmas-card-three" element={<ChristmasCardThree />} />

          {/* Proposal Card Routes */}
          <Route path="/proposal-card-one" element={<ProposalCardOne />} />
          <Route path="/proposal-card-two" element={<ProposalCardTwo />} />
          <Route path="/proposal-card-three" element={<ProposalCardThree />} />
          <Route path="/proposal-card-four" element={<ProposalCardFour />} />
          <Route path="/proposal-card-five" element={<ProposalCardFive />} />
          <Route path="/proposal-card-six" element={<ProposalCardSix />} />
          <Route path="/proposal-card-seven" element={<ProposalCardSeven />} />
          <Route path="/proposal-card-eight" element={<ProposalCardEight />} />
          <Route path="/proposal-card-nine" element={<ProposalCardNine />} />
          <Route path="/proposal-card-ten" element={<ProposalCardTen />} />
          <Route path="/proposal-card-eleven" element={<ProposalCardEleven />} />
          <Route path="/proposal-card-twelve" element={<ProposalCardTwelve />} />

          {/* Guess What Card Routes */}
          <Route path="/guess-what-card-one" element={<GuessWhatCardOne />} />
          <Route path="/guess-what-card-two" element={<GuessWhatCardTwo />} />
          <Route path="/guess-what-card-three" element={<GuessWhatCardThree />} />
          <Route path="/guess-what-card-four" element={<GuessWhatCardFour />} />
          <Route path="/guess-what-card-five" element={<GuessWhatCardFive />} />
          <Route path="/guess-what-card-six" element={<GuessWhatCardSix />} />
          <Route path="/guess-what-card-seven" element={<GuessWhatCardSeven />} />

          {/* Kama Sutra Calendar One Route */}
          <Route path="/kamasutra-calendar-part-one" element={<KamaSutraCalendarPartOne />} />
          <Route path="/kamasutra-calendar-part-two" element={<KamaSutraCalendarPartTwo />} />

          {/* Kama Sutra Adventskalendar Routes */}
          <Route path="/kamasutra-adventskalendar-part-one" element={<KamasutraAdventskalendarPartOne />} />
          <Route path="/kamasutra-adventskalendar-part-two" element={<KamasutraAdventskalendarPartTwo />} />

          {/* Valentines Card Routes */}
          <Route path="/valentines-card-one" element={<ValentinesCardOne />} />
          <Route path="/valentines-card-two" element={<ValentinesCardTwo />} />

          {/* Advent Calendar Route */}
          <Route path="/advent-calendar-1" element={<img src={AdventCalendar1} alt="Advent Calendar 1" />} />
          <Route path="/advent-calendar-2" element={<img src={AdventCalendar2} alt="Advent Calendar 2" />} />
          <Route path="/advent-calendar-3" element={<img src={AdventCalendar3} alt="Advent Calendar 3" />} />
          <Route path="/advent-calendar-4" element={<img src={AdventCalendar4} alt="Advent Calendar 4" />} />
          <Route path="/advent-calendar-5" element={<img src={AdventCalendar5} alt="Advent Calendar 5" />} />
          <Route path="/advent-calendar-6" element={<img src={AdventCalendar6} alt="Advent Calendar 6" />} />
          <Route path="/advent-calendar-7" element={<img src={AdventCalendar7} alt="Advent Calendar 7" />} />
          <Route path="/advent-calendar-8" element={<img src={AdventCalendar8} alt="Advent Calendar 8" />} />
          <Route path="/advent-calendar-9" element={<img src={AdventCalendar9} alt="Advent Calendar 9" />} />
          <Route path="/advent-calendar-10" element={<img src={AdventCalendar10} alt="Advent Calendar 10" />} />
          <Route path="/advent-calendar-11" element={<img src={AdventCalendar11} alt="Advent Calendar 11" />} />
          <Route path="/advent-calendar-12" element={<img src={AdventCalendar12} alt="Advent Calendar 12" />} />
          <Route path="/advent-calendar-13" element={<img src={AdventCalendar13} alt="Advent Calendar 13" />} />
          <Route path="/advent-calendar-14" element={<img src={AdventCalendar14} alt="Advent Calendar 14" />} />
          <Route path="/advent-calendar-15" element={<img src={AdventCalendar15} alt="Advent Calendar 15" />} />
          <Route path="/advent-calendar-16" element={<img src={AdventCalendar16} alt="Advent Calendar 16" />} />
          <Route path="/advent-calendar-17" element={<img src={AdventCalendar17} alt="Advent Calendar 17" />} />
          <Route path="/advent-calendar-18" element={<img src={AdventCalendar18} alt="Advent Calendar 18" />} />
          <Route path="/advent-calendar-19" element={<img src={AdventCalendar19} alt="Advent Calendar 19" />} />
          <Route path="/advent-calendar-20" element={<img src={AdventCalendar20} alt="Advent Calendar 20" />} />
          <Route path="/advent-calendar-21" element={<img src={AdventCalendar21} alt="Advent Calendar 21" />} />
          <Route path="/advent-calendar-22" element={<img src={AdventCalendar22} alt="Advent Calendar 22" />} />
          <Route path="/advent-calendar-23" element={<img src={AdventCalendar23} alt="Advent Calendar 23" />} />
          <Route path="/advent-calendar-24" element={<img src={AdventCalendar24} alt="Advent Calendar 24" />} />
          <Route path="/advent-calendar-25" element={<img src={AdventCalendar25} alt="Advent Calendar 25" />} />

        </Routes>
      </CheckToken>
      }
    </Router>
  );
}

export default App;
