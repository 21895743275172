import React, { useEffect, useRef, useState } from 'react';
import './CardOne.css';
import image from './scratch-image-background.png';
import day1 from './1.png';
import day2 from './2.png';
import day3 from './3.png';
import day4 from './4.png';
import day5 from './5.png';
import day6 from './6.png';
import day7 from './7.png';
import day8 from './8.png';
import day9 from './9.png';
import day10 from './10.png';
import day11 from './11.png';
import day12 from './12.png';

function ScratchCard() {
    const canvasRef = useRef(null);
    const imgRef = useRef(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      document.title = "12 Days of Sexmas";
      const canvas = canvasRef.current;
      const img = imgRef.current;
      const ctx = canvas.getContext('2d');
      
      const images = [
        day1, day2, day3,
        day4, day5, day6,
        day7, day8, day9,
        day10, day11, day12
      ];
      
      const loadedImages = images.map(src => {
        const img = new Image();
        img.src = src;
        return img;
      });
  
      // Define your circle data
      const circlesData = [
        { x: 65, y: 259, radius: 41, image: loadedImages[0] },
        { x: 181, y: 259, radius: 41, image: loadedImages[1] },
        { x: 297, y: 259, radius: 41, image: loadedImages[2] },
    
        { x: 65, y: 352, radius: 41, image: loadedImages[3] },
        { x: 181, y: 352, radius: 41, image: loadedImages[4] },
        { x: 297, y: 352, radius: 41, image: loadedImages[5] },
    
        { x: 65, y: 445, radius: 41, image: loadedImages[6] },
        { x: 181, y: 445, radius: 41, image: loadedImages[7] },
        { x: 297, y: 445, radius: 41, image: loadedImages[8] },
    
        { x: 65, y: 540, radius: 41, image: loadedImages[9] },
        { x: 181, y: 540, radius: 41, image: loadedImages[10] },
        { x: 297, y: 540, radius: 41,image: loadedImages[11] },
    ];
  
      let circles = []; // This will store scaled circle data
  
      function updateCirclePositions() {
        const scaleX = canvas.width / 360;
        const scaleY = canvas.height / 640;
        circles = circlesData.map(circle => ({
          x: circle.x * scaleX,
          y: circle.y * scaleY,
          radius: circle.radius * Math.min(scaleX, scaleY),
          image: circle.image
        }));
      }
  
      function drawCircles() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#cccccc';
        circles.forEach(circle => {
          const imageWidth = circle.radius * 2;
          const imageHeight = circle.radius * 2;
          const imageX = circle.x - imageWidth / 2;
          const imageY = circle.y - imageHeight / 2;
          ctx.drawImage(circle.image, imageX, imageY, imageWidth, imageHeight);
        });
        ctx.globalCompositeOperation = 'destination-out';
      }
  
      function resizeCanvas() {
        canvas.width = img.width;
        canvas.height = img.height;
        updateCirclePositions();
        drawCircles();
      }
  
      loadedImages.forEach(image => {
        image.onload = () => {
          resizeCanvas(); // Resize canvas once all custom shape images are loaded
        };
      });
  
      // Resize canvas on page load
      resizeCanvas();
  
      // Listen for resize events to adjust canvas and circle sizes
      window.addEventListener('resize', resizeCanvas);
  
      if (img.complete) {
        resizeCanvas(); // If the image is already loaded (e.g., cached), resize immediately
      } else {
        img.onload = resizeCanvas; // Wait for the image to load before resizing
      }
  
      function getCoordinates(e) {
        const rect = canvas.getBoundingClientRect();
        let x, y;
  
        if (e.touches) {
          x = e.touches[0].clientX - rect.left;
          y = e.touches[0].clientY - rect.top;
        } else {
          x = e.clientX - rect.left;
          y = e.clientY - rect.top;
        }
  
        return { x, y };
      }
  
      let lastX, lastY, isDrawing = false;
  
      function scratch(e) {
        const { x, y } = getCoordinates(e);
  
        if (isDrawing) {
          if (lastX == null || lastY == null) {
            lastX = x; // Set starting position if not already set
            lastY = y;
          }
        }
  
        circles.forEach(circle => {
          const distance = Math.sqrt((x - circle.x) ** 2 + (y - circle.y) ** 2);
          if (distance < circle.radius) {
            ctx.lineWidth = 15;
            ctx.beginPath();
            ctx.moveTo(lastX, lastY);
            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.closePath();
          }
        });
  
        [lastX, lastY] = [x, y];
      }
  
      // Mouse event listeners
      canvas.addEventListener('mousedown', function (e) {
        isDrawing = true; // Start drawing
        lastX = null; // Reset lastX and lastY
        lastY = null;
        scratch(e); // Handle initial touch point
      });
  
      canvas.addEventListener('mousemove', function (e) {
        if (isDrawing) {
          scratch(e);
        }
      });
  
      canvas.addEventListener('mouseup', function () {
        isDrawing = false; // Stop drawing
      });
  
      canvas.addEventListener('mouseout', function () {
        isDrawing = false; // Stop drawing when the cursor leaves the canvas
      });
  
      canvas.addEventListener('touchstart', function (e) {
        e.preventDefault(); // Prevent scrolling and other default actions
        isDrawing = true;
        lastX = null; // Reset lastX and lastY
        lastY = null;
        scratch(e.touches[0]); // Use the first touch point to start
      });
  
      canvas.addEventListener('touchmove', function (e) {
        e.preventDefault();
        if (isDrawing) {
          scratch(e.touches[0]); // Continue with the first touch point
        }
      });
  
      canvas.addEventListener('touchend', function (e) {
        isDrawing = false; // Stop drawing when touch ends
      });
  
      canvas.addEventListener('touchcancel', function (e) {
        isDrawing = false; // Also stop drawing when touch is interrupted
      });
  
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Hide the spinner after 1 second
    }, []);
  
    return (
      <div className="scratch-card">
        {loading && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
          </div>
        )}
        <canvas ref={canvasRef} id="scratchCanvas"></canvas>
        <img ref={imgRef} src={image} alt="Scratch Card" />
      </div>
    );
  }
  
  export default ScratchCard;

